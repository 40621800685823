import { QueryClient } from 'react-query';
import isBrowser from '../util/isBrowser';

const guardianQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            enabled: isBrowser,
            retry: false,
        },
    },
});

export default guardianQueryClient;
