import axios, { AxiosInstance } from 'axios';
// eslint-disable-next-line import/no-cycle
import { useSessionStore } from '@src/store/appState';

/**
 * @type {AxiosInstance}
 */
const BACKEND_API = axios.create({
    baseURL: `${process.env.GATSBY_BACKEND_API_BASE_URL}`,
});

BACKEND_API.interceptors.request.use(
    async (config) => {
        if (!process.env.GATSBY_BACKEND_API_XANO_BRANCH) {
            return config;
        }

        return {
            ...config,
            headers: {
                ...config.headers,
                'X-Branch': process.env.GATSBY_BACKEND_API_XANO_BRANCH,
            },
        };
    },
);
BACKEND_API.interceptors.request.use(
    async (config) => {
        if (config.skipAuth || config.headers.Authorization) {
            return config;
        }
        const {
            token,
            refreshToken,
            navigateToLogin,
        } = useSessionStore.getState();

        if (!token) {
            throw new Error('Unable to make secure request, current session does not have a token set.');
        }
        let accessToken = token.access_token;

        if (token.expires_at < new Date().getTime()) {
            try {
                accessToken = await refreshToken(token.refresh_token);
            } catch (error) {
                await navigateToLogin();
                await new Promise(() => {});
            }
        }

        // eslint-disable-next-line no-param-reassign
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        };

        return config;
    },
    (error) => {
        console.error('error: ', error);
        return Promise.reject(error);
    },
);

export {
    BACKEND_API,
};
