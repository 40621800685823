import {useSessionStore} from "../store/appState";
import {useGetCurrentUserQuery} from "@src/apiFetch/authApi";
import {navigate} from "gatsby";

export type User = {
    user_first_name: string;
    user_last_name: string;
    email: string;
    id: string;
}

const useUserSession = () => {
    const {
        token,
        setToken,
        justLoggedOut,
        setJustLoggedOut,
        refreshToken,
        navigateToLogin,
        isActivatingToken,
    } = useSessionStore();

    const logOut = async () => {
        if (!user) {
            await navigate("/");
            return;
        }
        setJustLoggedOut(true);
        setToken(null);
        window.location.href = user.logout_url;
    }

    const getAccessToken = async () => {
        if (!token) {
            throw new Error("Unable to make secure request, current session does not have a token set.");
        }
        let accessToken = token.access_token;

        if (token.expires_at < new Date().getTime()) {
            try {
                accessToken = await refreshToken(token.refresh_token);
            } catch (error) {
                console.error(error);
                navigateToLogin();
            }
        }

        return accessToken
    }

    const {data: user, isLoading} = useGetCurrentUserQuery(getAccessToken, {
        enabled: token !== null && !isActivatingToken,
    });

    return {
        isLoggedIn: token !== null,
        isRetrievingUser: isLoading,
        logOut,
        tokenExpirationTime: token?.expires_at,
        getAccessToken,
        user,
        justLoggedOut,
        navigateToLogin
    }
}

export default useUserSession;
