import * as React from 'react';
import useUserSession from '@src/hooks/useUserSession';
import loadable from '@loadable/component';

const ProductFruits = loadable(
    () => import('react-product-fruits').then((mod) => mod.ProductFruits),
    { ssr: false },
);

const ProductFruitProvider = () => {
    const { user } = useUserSession();

    const userInfo = user ? {
        username: user.user_email,
        email: user.user_email,
        firstname: user.user_first_name,
        lastname: user.user_last_name,
        // signUpAt: '2021-12-30T23:50:55', // Sign up date (yyyy-mm-ddThh:mm:ss)
    } : null;

    if (!userInfo || !process.env.GATSBY_PRODUCT_FRUITS_ID) {
        return null;
    }

    return (
        <ProductFruits
            workspaceCode={process.env.GATSBY_PRODUCT_FRUITS_ID}
            language="en"
            user={userInfo}
        />
    );
};

export default ProductFruitProvider;
