import {useMutation, useQuery} from "react-query";
import {AxiosResponse} from "axios";
import {User} from "../hooks/useUserSession";
import {BACKEND_API} from "./axiosConfig";

type OAuthTokenResponse = {
    access_token: string,
    expires_in: number,
    id_token: string,
    refresh_token: string;
    scope: string;
    token_type: string
};

const useRedeemAuthorizationCodeMutation = (mutationOpts = undefined) => {
    return useMutation(({code}: { code: string }) => {
        const redirectUri = `${window.location.origin}/login-callback`;
        return BACKEND_API.post<any, AxiosResponse<OAuthTokenResponse>>(
            "auth/redeem_authorization_code",
            {
                redirect_uri: redirectUri,
                code
            },
            {
                skipAuth: true
            }
        )
    }, mutationOpts);
}

export const useGetCurrentUserQuery = (getAccessToken: () => Promise<string>, queryOpts = undefined) => {
    return useQuery(["getCurrentUser"], async () => {
        return (await BACKEND_API.get<any, AxiosResponse<User>>(
            "/me"
        )).data;
    }, queryOpts);
}

export default useRedeemAuthorizationCodeMutation;
